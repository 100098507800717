import { Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Input } from "../../../../components/InputFields";
import { SidebarLayout } from "../../../../components/Layout";
import SettingsSidebar from "../../../../components/SettingsSidebar";
import { addMessage } from "../../../../state/reducers/appReducer";
import { fetchBaseOptions } from "../../../../utils/fetch";
import { passwordValidator, repeatPasswordValidator } from "../../../../utils/validators";

const validationSchema = yup.object().shape({
  currentPassword: passwordValidator,
  password: passwordValidator,
  repeatPassword: repeatPasswordValidator,
});

const UpdatePasswordPage = () => {
  const dispatch = useDispatch();
  return (
    <SidebarLayout sidebar={<SettingsSidebar />} seo={{ title: "Change password" }}>
      <Formik
        initialValues={{ currentPassword: "", password: "", repeatPassword: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          fetch("/api/user/password/update", {
            ...fetchBaseOptions,
            method: "PATCH",
            body: JSON.stringify(values),
          })
            .then(async res => {
              const j: IAPIResponse = await res.json();
              dispatch(addMessage(j));
              if (res.ok) {
                resetForm();
              }
            })
            .catch(err => console.error(err))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="space-y-2">
            <Field
              required={true}
              type="password"
              name="currentPassword"
              label="Current password"
              component={Input}
            />
            <Field
              required={true}
              type="password"
              name="password"
              label="New password"
              component={Input}
            />
            <Field
              required={true}
              type="password"
              name="repeatPassword"
              label="Repeat password"
              component={Input}
            />
            <button
              className="w-full btn btn-primary"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </SidebarLayout>
  );
};

export default UpdatePasswordPage;
